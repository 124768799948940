export const useUIFetch = (locale, url, params, options = {}) => {

	const config = useRuntimeConfig();
	options.baseURL = locale === 'cs' ? config.public.baseURLCS : config.public.baseURLEN
	return $fetch(url, {
		...options,
		params,
		credentials: 'include',
		onResponseError({ request, response, options }) {
			console.log('error')
		}
	})
}